<template>
  <nuxt-link v-bind="userDetailAttributes" to="/account" @click="emit('on-close')">
    <div class="user-detail__info--wrapper">
      <div class="user-detail__img">
        <div
          v-if="!selected"
          class="user-detail__img--change"
          @click="changeUserImg"
          v-text="$t('general.change')"
        />
        <nuxt-img
          :src="isProfilePicture ? userPicture : defaultUserPicture"
          :width="size === 'sm' ? '40' : '56'"
          :height="size === 'sm' ? '40' : '56'"
          alt="Profile"
        />
      </div>
      <div class="user-detail__info">
        <h4 class="user-detail__info--name" v-text="user.name" />
        <div class="user-detail__info--verify">
          <p-icon icon="verify" size="xs" color="foreground-accent" />
          <span v-if="user.verified" v-text="$t('general.verified')" />
        </div>
      </div>
    </div>
    <p-icon v-if="selected" icon="caret-right" size="xs" color="foreground-tertiary" />
  </nuxt-link>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-close'])

const isProfilePicture = ref(false)
const defaultUserPicture = '/img/default-picture.svg'
const userPicture = ''
const user = ref({
  name: 'John Doe',
  verified: true,
})

const props = defineProps({
  selected: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
    validator: (val: string) => ['sm', 'md'].includes(val),
  },
})

const userDetailAttributes = computed((): Record<string, any> => {
  return {
    class: {
      'user-detail': true,
      [`user-detail--${props.size}`]: true,
      'cursor-auto': !props.selected,
    },
  }
})

const changeUserImg = () => {
  // todo: change user img
}
</script>
