<template>
  <div v-for="{ to, title, prepend } in supportItems" :key="title">
    <nuxt-link :to="to" @click="itemClickHandler(to.path)">
      <p-list hover :title="title" :selected="isActive(to.path)">
        <template #prepend>
          <p-icon v-if="prepend" :icon="prepend" size="sm" color="foreground-secondary" />
        </template>
        <template #append>
          <p-icon v-if="activeItem === to.path" icon="caret-right" size="xs" color="highlight" />
        </template>
      </p-list>
    </nuxt-link>
  </div>
  <p-divider class="my-16" />
  <p-list hover :title="$t('general.signOut')" class="cursor-pointer" @on-click="signOut">
    <template #prepend>
      <p-icon icon="sign-out" size="sm" color="foreground-danger" />
    </template>
  </p-list>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-close'])

const router = useRouter()
const route = useRoute()
const activeItem = ref('')
const supportItems = computed((): Record<string, any> => {
  return [
    {
      prepend: 'theme',
      to: { path: '/' },
      title: $t('general.theme'),
    },
    {
      prepend: 'call-center',
      to: { path: '/' },
      title: $t('general.supportCenter'),
    },
    {
      prepend: 'privacy-policies',
      to: { path: '/' },
      title: $t('general.privacyPolicies'),
    },
    {
      prepend: 'blog',
      to: { path: '/' },
      title: $t('general.blog'),
    },
  ]
})

const itemClickHandler = (path: string) => {
  activeItem.value = path
  emit && emit('on-close')
}

const isActive = (path: string) => route.path === path

onMounted(() => {
  const currentPath = route.path
  const matchingItem = supportItems.value.find(
    (item: { to: { path: string } }) => item.to.path === currentPath,
  )
  if (matchingItem) {
    activeItem.value = currentPath
  }
})

const signOut = () => {
  // todo: sign out
  router.push('/')
  emit && emit('on-close')
}
</script>
